

.loading{
    position: fixed;
    top: 0;
    z-index: 200;
    left: 0;
    width: 100%;
}

.socialcontainer{
    display:  flex;
    align-items: center;

    justify-content: center;
 
}
.soclialbutton{

    background: white;
    padding: 10px;
    width: 150px;
    height: 45px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 30px;
    margin: 10px;
}


.soclialbutton .icon img{
    height: 20px;

}
.soclialbutton .name{
    margin-left: 5px;
 
    text-align: left;
    
}
.soclialbutton .name p{
    text-align: left;
    font-size: 10px;
    margin-bottom: -5px;
    color: #0F2E65;
}
.soclialbutton .name a{
    text-align: left;
    font-size: 14px;
    color: #0F2E65;
 
    
}


.footer{
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(261.77deg, #0F2E65 -1.64%, #1ED6E1 100%);
}
.footer .footermain{
    font-size: 24px;
}
.footer .footertext p{
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
}
.App{
    min-height: 80vh;
  }
  