.NostoreFound{
   
    height: 70vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.scrollContainer{
    width: 80%;
    margin-left: 10%;
}

.offerbanner{
    

    position: absolute;
    bottom: -15%;
    width: 100%;
    left: 0;
    background: #0F2E65 !important;
    border-radius: 0px 0px 10px 10px;
    height: 50px;
    overflow: hidden;
    display: flex;
    align-items: center;


}

.offerbanner .previousButton{
    position: absolute;
    left: 30px;
    font-size: 16px;
    display: flex;
    align-items: center;
    z-index: 100;
   
}

.offerbanner .nextButton{
    position: absolute;
    right: 30px;
    font-size: 16px;
    z-index: 100;
    display: flex;
    align-items: center;
 
   
}
.offerbanner .previousButton svg ,.offerbanner .nextButton svg{
    height: 20px !important;
    color: white !important;
    
}
.polygon {
    fill: white !important;
    color: white !important;
}
.current{
    margin-top: 0px !important;
}
.offerbanner p{
  height: 50px;
  background: #0F2E65;;
    margin-top: -10px !important;
    cursor: pointer;
    border-radius: 0px 0px 10px 10px;
    color: white !important;
    width: 50%;
    margin-left: 25%;
    font-size: 14px;

}
.NoProduct{
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


.productContainer{

    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.productContainer .itemCard{
    min-width: 330px;
    width: 330px;
    display: flex;
    margin: 10px;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 2px black;
    min-height: 150px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;

}
.productContainer .itemCard:hover{
    box-shadow: 0px 0px 10px 4px #081936;
}

.Addtocart {
    background: #0F2E65;
    padding: 5px 20px;
    width: fit-content;
    margin: 10px 0px;
    color: white;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s ease-in-out;
}
.Addtocart:hover{
    background: #1c4388;
}
.itemCard .itemCardDetails{
    display: flex;

    width: 230px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.itemCard .itemCardDetails p{
    text-align: left;
    padding-left: 5px;
  
    width: 100%;
    font-size: 14px;
}
.itemCard .itemCardimg{
    width: 100px;
    height: 100px;
}

.itemCard .itemCardimg img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.homecontainer{
    width: 100%;
    text-align: center;


}
.homecontainer .banner{

    width: 100%;
    height: 30vh;
    position: relative;
    overflow: hidden;
}
.homecontainer .banner img{
    width: 100%;
  height: 100%;
  object-fit: cover;
}

.SearchAndCatagory{
    width: 80%;
    margin-left: 10%;
    padding: 10px;
    top: -25px;
    border-radius: 15px;
    position: relative;
    background: white;
    box-shadow: 0px 0px 10px 2px black;

}
.SearchAndCatagory .Search{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    
}
.searchbar{
    width: 90%;
    padding: 10px;
    border-top: none;
    border-left: none;
    border-right:none ;
    border-bottom: 1px solid black;
    outline: none;
}
.SearchAndCatagory .catagory {
    display: flex;

    width: 90%;
    margin-top: 10px;
    margin-left: 5%;
    align-items: center;

}
.CatagoryTtile{
    font-size: 16px !important;
    font-weight: 900;
}
.SearchAndCatagory .catagory .catagoryContainer {
    display: flex;
    width: 100%;

    
    padding:20px 10px;
    overflow-x: auto;

    border-radius: 20px;

    
    position: relative;
}
.rightshadow{
    position: absolute;
    width: 100px;

    height: 100%;
    top: 0;
    right: 0;
}
.catagoryContainer div .count{
    position: absolute;
    background: #0F2E65;
    color: white;
    height: 30px !important;
    border-radius: 100% ;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px !important;
    padding: 0;
    top: -10px;
    right: -10px;
}
.catagoryContainer div .nocount {
    display: none;
}

.catagoryContainer div{
    position: relative;
    margin-left: 15px;
    background: white;
    user-select: none;
    
    padding: 15px 20px;
    border-radius: 10px;
 display: flex;
 white-space: nowrap;
 align-items: center;
 height: 10px;
 color: #0F2E65;
 cursor: pointer;
 transform: scale(0.9);
    width: fit-content !important;
    transition: all 0.5s ease-in-out;
}
.Search .closeicon{
    transition: all 0.5s ease-in-out;
}
.catagoryContainer div:hover{
    color:white;
    background:#203d6e;

}
.activecat{
    background: #0F2E65 !important;
    color: white !important;
    transform: scale(1) !important;
}
.SearchAndCatagory .catagory p{
    font-size: 14px;
    color: #0F2E65;
 
}
.banner .layerblack{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    padding: 20px;
    top: 0;
    left: 0;
    background: rgb(245,245,245);
    background: linear-gradient(0deg, rgba(12, 12, 12, 0.363) 0%, rgba(0, 0, 0, 0.644) 100%);
}
.instruction{
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #0F2E65;
}
.details{
    
    width: 80%;
    margin-left: 10%;
    padding: 20px 30px;
    top: 0px;
    border-radius: 15px;
    position: relative;
    background: white;
    box-shadow: 0px 0px 10px 2px black;
margin-bottom: 10px;
}
.details .storeinfo{
    display:  grid;
    position: relative;
    
    grid-template-columns: 1fr 1fr;
  
}
.StoreInformation h1,.StoreInformation p{
   color: white;
}
.StoreInformation p{
    font-weight: 500;
    font-size: 20px;
}
.storedetails{
    padding: 5px;
    text-align: left;
    border-right: solid 2px grey;
}
.appinfo{
    padding: 5px;
}
.storedetails h1{
    font-size: 34px;
}
.contactinfo{
    width: fit-content;
    min-width: 200px;

    
    display: flex;
    align-items: center;
    margin-top: 10px;
    
}
.contactinfo .icons{
    width: 20px;
    margin-right: 15px;
}
.contactinfo img{
    height: 20px; 
 
}
.appinfo{
    display: flex;
    text-align: left;
    align-items: center;
}
.appinfo .videoconariner{
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.055);

    margin: 10px;
    padding: 10px;
    border-radius: 15px;
    position: relative;
    width: 100%;
}
.appinfo .videoconariner:hover .overflayicon{
    opacity: 1;
    transform: translateY(00px);
    cursor: pointer;
}
.appinfo .videoconariner:focus .overflayicon{
    opacity: 1;
    transform: translateY(00px);
    cursor: pointer;
}
.appinfo .videoconariner:hover .videoicon{
    opacity: 1;
    transform: translateY(00px);
    cursor: pointer;
}
.appinfo .videoconariner:focus .videoicon{
    opacity: 1;
    transform: translateY(00px);
    cursor: pointer;
}
.overflayicon{
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.431);
    transition: 0.3s all ease-in-out;
    opacity: 0;
   
}
.videoicon {
    opacity: 0;
    transition: 0.5s all ease-in-out;
    transform: translateY(20px);
}
.videoicon img{
    margin: 10px;
}
.cardcontainer{
 
    display: flex;
    flex-wrap: nowrap;
    width: 90%;
    margin-left: 5%;
    overflow-x: auto;
    margin-top: 5%;
    margin-bottom: 20px;
}
.cardcontainer .card{
    
    margin: 10px;
    border-radius: 15px;
    height: 300px;
    width: 500px;
    overflow: hidden;
    min-width: 500px;
    position: relative;
    cursor: pointer;
}
.cardcontainer .card .backimage{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.cardcontainer .card .backimage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.cardcontainer .card .blacklayer{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgb(245,245,245);
background: linear-gradient(180deg, rgba(8, 8, 8, 0) 0%, rgba(0, 0, 0, 0.794) 100%);
}
.cardcontainer .card .carddetails{
    z-index: 10;
    position: relative;
    text-align: left;
    height: 100%;
}
.card .carddetails .logo{
   border-radius: 15px;
    background: white;
    margin: 10px;
    height: 70px;
}
.cardcontainer .card .carddetails .innerText{
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 10px;
    
}
.cardcontainer .card .carddetails .innerText p{
    color: white;
    font-size: 18px;
}
.cardcontainer .card .carddetails .innerText  .categoryname{
    font-size: 24px;
    font-weight: bold;
}
.offerContainer{
    margin-top: 10%;
    margin-bottom: 5%;
    display: flex;
    align-items: center;
    flex-direction: column;

}
.offerContainer .offer{
    align-items: center;
    padding:0px 0px;

    width: fit-content;
    max-width: 90%;
    margin-left: 0;
 
    padding: 20px;
    overflow-y: auto;
  

}
.offer .offercard:hover{
    box-shadow: 0px 0px 4px 2px black;
    transform: scale(1.05);
}
.offer .offercard{
cursor: pointer;
    overflow: hidden;
    width: 350px;
    height: 200px;
    border-radius: 15px;
    text-align: left;
    min-width: 350px;
    position: relative;
    margin-right: 20px;
    transition: all 0.5s ease-in-out;
  
}

.offercard .logo{
    border-radius: 15px;
    background: white;
    margin: 10px;
    position: relative;
    z-index: 10;
    height: 60px;
}
.offercard .linerlayer{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    

}
.offername{
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    margin-bottom: 10px;
    color: white;
    font-size: 18px;
}

@media (max-width:660px) {
    .offerbanner p{
        margin-top: -20px !important;
    }
    .offerbanner{
        bottom: -30%;
    }
    .StoreInformation h1{
        color: white;
        font-size: 20px;
     }
     .StoreInformation p{
        color: white;
        font-size: 16px;
     }
    .SearchAndCatagory{
        width: 95%;
        margin-left: 2.5%;
    }
    .homecontainer .banner img{
        width: 100%;
        height: 100%;
    }
    .banner .layerblack{
        padding: 10px;
    }
    
    .details{

        padding: 10px 5px;
        top: -0px;
        width: 95%;
        margin-left: 2.5%;
        
    
    }
    .details h1{
        font-size: 22px;
    }
    .details p{
        font-size: 14px;
    }
    .details .storeinfo{
      
        
        grid-template-columns: 1fr ;
      
    }
    .storedetails{
        
        border-right: solid 0px grey;
    }
    .appinfo{
      
        display: flex;
        align-items: center;
        flex-direction: column;
        
   
    }
    .appinfo .videoconariner{
       
        padding: 0px;
        margin: 0px;
       
    }
    .cardcontainer .card{
    
      
        height: 350px;
        width: 300px;
       
        min-width: 300px;
        
    }
    .offer .offercard{
        cursor: pointer;
            overflow: hidden;
            width: 250px;
            height: 200px;
            border-radius: 15px;
            text-align: left;
            min-width: 250px;
            position: relative;
            margin-right: 10px;
            transition: all 0.5s ease-in-out;
        }
        .scrollContainer{
            width: 95%;
            margin-left: 2.5%;
        }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .homecontainer .banner img{
        width: 100%;
        height: 100%;
    }
    .details{

        padding: 10px 5px;
        top: -100px;
        
    
    }
    .details .storeinfo{
      
        
        grid-template-columns: 1fr 1fr;
      
    }
    .appinfo{
      
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
 
   }