.privacy-container {
    width: 80%;
    margin: 5rem auto 2rem;
    overflow: hidden;
}
.privacy-container .loading-privacy {
    text-align: center;
}
.privacy-container h3, .privacy-container p {
    margin: 12px 0 8px;
}
.privacy-container ul {
    margin-left: 1.2rem;
}
.privacy-container ul li, .privacy-container ol li {
    margin: 8px 0;
}
.not-found {
    padding-top: 10rem;
    text-align: center;
}
.privacy-container .go-back-btn {
    margin-top: 1.2rem;
}